import React, { useState, useEffect } from "react";
import queryString from "query-string";
import axios from "../../components/common/axios";
import useAuth from "../../hooks/useAuth";
import Spinner from "../../components/common/Spinner";
import { Capitalize } from "../../functions/utils";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

const QueryDetails = ({ history }) => {
  const { user, logout } = useAuth();
  const [isLoading, setIsLoading] = useState([]);
  const [query, setQuery] = useState({});

  useEffect(() => {
    getQuery();
  }, []);

  const getQuery = async () => {
    try {
      const { id } = queryString.parse(history.location.search);
      if (!id) {
        history.goBack();
      }
      setIsLoading(true);
      const response = await axios.get(`/message/${id}`, {
        headers: {
          Authorization: user.token,
        },
      });

      if (!response.data.body.isRead) {
        updateStatus(response.data.body.id);
      }
      setQuery({ ...response.data.body });
      setIsLoading(false);
    } catch (err) {
      console.error(err.message);
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          toast.error("Session expired. Please login again.");
          await logout();
          return;
        }
        toast.error(err.response.data.message);
      }
    }
  };

  const updateStatus = async (updateId) => {
    try {
      const response = await axios.put(
        "/message",
        { isRead: true, updateId },
        {
          headers: {
            Authorization: user.token,
          },
        }
      );
    } catch (err) {
      console.error(err.message);
    }
  };

  return isLoading ? (
    <div
      className=" mt-10"
      style={{
        textAlign: "-webkit-center",
      }}
    >
      <Spinner size={10} />
    </div>
  ) : (
    <>
      <div className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Complaint Query Detail
            </h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              onClick={() => history.goBack()}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-500 px-4 py-2 my-3 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4 w-full mx-auto sm:px-6">
        <div className="w-full">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h2
                id="applicant-information-title"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Query Information
              </h2>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Title</dt>
                  <dd className="mt-1 text-sm text-gray-900">{query.title}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    User Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {query.User ? query.User.name : "-"}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    User Email
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {query.User ? query.User.email : "-"}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Source</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {query.source || "Mobile"}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Is Read</dt>
                  <dd className="mt-1 text-sm text-gray-900">Yes</dd>
                </div>
              </dl>
              <div className="mt-8">
                <dt className="text-sm font-medium text-gray-500">Message</dt>
                <dd className="mt-1 text-sm text-gray-900">{query.message}</dd>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(QueryDetails);
