import moment from "moment";

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const isObject = (a) => {
  return !!a && a.constructor === Object;
};

export const numberFormat = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "m" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const Capitalize = (string) => {
  if (!string) return;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getExpiryTime = (duration) => {
  let minutes = 0,
    hours = 0;

  if (duration > 59) {
    let converted = duration / 60;
    hours = Math.floor(converted);
    converted = "0." + (converted + "").split(".")[1];
    minutes = Math.floor(converted * 60);
  }

  let time = "";

  if (minutes) {
    time = `${minutes} Minutes`;
  }

  if (hours) {
    time = `${hours} Hours ${time}`;
  }
  return time;
};

export const filterUrl = (filterArray) => {
  let url = "";
  filterArray.map((item) => {
    if (url && item.value) url = url + "&";
    if (item.type === "select") {
      if (item.value) {
        url = url.concat(`${item.name}=${item.value.value}`);
      }
    } else if (item.value) {
      url = url.concat(`${item.name}=${item.value}`);
    }
  });
  return url;
};
