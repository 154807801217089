import React, { useState, useEffect } from "react";
import query from "query-string";
import axios from "../../components/common/axios";
import useAuth from "../../hooks/useAuth";
import Spinner from "../../components/common/Spinner";
import DefaultImage from "../../assets/img/user_default_image.jpeg";
import { Capitalize } from "../../functions/utils";
import { PhotographIcon } from "@heroicons/react/outline";
import { Link, withRouter } from "react-router-dom";
import { CardDetailsModal } from "../../components/common/CardDetailsModal";
import { ImageModal } from "../../components/common/ImageModal";
import { toast } from "react-toastify";

const UserDetails = ({ history }) => {
  const { user, logout } = useAuth();
  const [isLoading, setIsLoading] = useState([]);
  const [users, setUser] = useState({});
  const [card, setCard] = useState(null);
  const [openImage, setOpenImage] = useState(null);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const { id } = query.parse(history.location.search);
      if (!id) {
        history.goBack();
      }
      setIsLoading(true);
      const response = await axios.get(`/users/${id}`, {
        headers: {
          Authorization: user.token,
        },
      });
      setIsLoading(false);

      setUser({ ...response.data.body });
    } catch (err) {
      console.error(err.message);
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          toast.error("Session expired. Please login again.");
          await logout();
          return;
        }
        toast.error(err.response.data.message);
      }
    }
  };
  return isLoading ? (
    <div
      className=" mt-10"
      style={{
        textAlign: "-webkit-center",
      }}
    >
      <Spinner size={10} />
    </div>
  ) : (
    <>
      <div className="w-full mx-auto mt-4 px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div className="flex items-center justify-between space-x-5">
          <div className="flex-shrink-0">
            <div
              className="relative cursor-pointer"
              onClick={() => {
                setOpenImage(users.profilePic);
              }}
            >
              <img
                className="h-16 w-16 rounded-full"
                src={users.profilePic || DefaultImage}
                alt=""
              />
              <span
                className="absolute inset-0 shadow-inner rounded-full"
                aria-hidden="true"
              />
            </div>
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{users.name}</h1>
            <p className="text-sm font-medium text-gray-500">
              Created at{" "}
              <time dateTime="2020-08-25">
                {new Date(users.createdAt).toDateString()}
              </time>
            </p>
          </div>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link to={`/update-user?id=${users.id}`}>
            <button
              type="button"
              className="mx-2 inline-flex items-center justify-center rounded-md border border-transparent bg-purple px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Edit User
            </button>
          </Link>
          {/* <Link to={`/user-list`}> */}
          <button
            type="button"
            onClick={() => history.push("/user-list")}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-purple px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Go Back
          </button>
          {/* </Link> */}
        </div>
      </div>

      <div className="mt-8 w-full mx-auto sm:px-6">
        <div className="w-full">
          {/* Description list*/}
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h2
                id="applicant-information-title"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                User Personal Information
              </h2>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 text-sm text-gray-900">{users.email}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">DOB</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {new Date(users.dob).toDateString()}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Height</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {users.height ? `${users.height} cm` : 0}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Weight</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {users.weight ? `${users.weight} kg` : 0}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Gender</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {Capitalize(users.gender)}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Total Coins
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {users.totalCoins || 0}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Daily Foot Steps Goal
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {users.dailyFSGoal || 0}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Tracking
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {users.isTracking ? "Enabled" : "Disabled"}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Status</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {users.isActive ? "Active" : "Inactive"}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white shadow sm:rounded-lg mt-3">
            <div className="px-4 py-5 sm:px-6">
              <h2
                id="applicant-information-title"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Associated Gift Cards
              </h2>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <dd className="mt-1 text-sm text-gray-900">
                    <ul
                      role="list"
                      className={
                        users.GiftCards && users.GiftCards.length
                          ? "border border-gray-200 rounded-md divide-y divide-gray-200"
                          : ""
                      }
                    >
                      {users.GiftCards && users.GiftCards.length
                        ? users.GiftCards.map((card) => (
                            <li
                              key={card.id}
                              className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                            >
                              <div className="w-0 flex-1 flex items-center">
                                <PhotographIcon className="h-8 w-8 rounded-md" />
                                <span className="ml-2 flex-1 w-0 truncate">
                                  {card.serialNumber}
                                </span>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <span
                                  className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
                                  onClick={() => {
                                    setCard(card);
                                  }}
                                >
                                  View Details
                                </span>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                    {users.GiftCards && !users.GiftCards.length ? (
                      <div
                        style={{
                          textAlign: "-webkit-center",
                        }}
                        className=" text-body-md"
                      >
                        No gift cards are associated with this account.
                      </div>
                    ) : null}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <CardDetailsModal card={card} setCard={setCard} history={history} />
        <ImageModal
          image={openImage}
          title="User Profile Pic"
          setOpenImage={setOpenImage}
        />
      </div>
    </>
  );
};

export default withRouter(UserDetails);
