/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import Spinner from "./Spinner";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Select = ({
  id,
  name,
  options,
  label,
  value,
  error,
  helperText,
  onChange,
  loading,
  placeholder,
  className,
  required,
  dropDownZIndex,
  ...rest
}) => {
  if (!options) options = [];
  return (
    <div className="mt-4">
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              {label}
              {required ? <span className="text-red-600">*</span> : null}
            </Listbox.Label>
            <div className="mt-1 relative">
              <Listbox.Button className={className}>
                <span
                  className={`block truncate ${!value ? "text-gray-500" : ""}`}
                >
                  {value ? value.label : placeholder || ""}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  {loading ? <Spinner size={5} /> : null}{" "}
                  <SelectorIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        classNames(
                          value.label === option.label
                            ? "text-white bg-purple"
                            : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-8 pr-4"
                        )
                      }
                      value={option}
                    >
                      {({ active }) => (
                        <>
                          <span
                            className={classNames(
                              value.label === option.label
                                ? "font-semibold"
                                : "font-normal",
                              "block truncate"
                            )}
                          >
                            {option.label}
                          </span>

                          {value.label === option.label ? (
                            <span
                              className={classNames(
                                value.label === option.label
                                  ? "text-white"
                                  : "text-indigo-600",
                                "absolute inset-y-0 left-0 flex items-center pl-1.5"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {error ? (
        <p className="mb-0 text-red-600 text-body-3xs ml-2">{helperText}</p>
      ) : null}
    </div>
  );
};

export default Select;
