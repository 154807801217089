/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  DocumentTextIcon,
  PencilAltIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Capitalize } from "../../functions/utils";

export const CardDetailsModal = ({ card, setCard, history }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (card) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [card]);

  if (!card) return null;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setCard(null)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4 flex-row mt-3">
                  <button
                    type="button"
                    className="bg-white rounded-md mr-1 text-blue-600 hover:text-blue-400"
                    onClick={() => history.push(`/update-card?id=${card.id}`)}
                  >
                    <span className="sr-only">Edit</span>
                    <PencilAltIcon className="h-6 w-6" aria-hidden="true" />
                  </button>{" "}
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                    onClick={() => setCard(null)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start border-b pb-2">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <DocumentTextIcon
                      className="h-6 w-6 text-blue-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mt-1"
                    >
                      Card Details
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-4">
                  <div style={{ textAlign: "-webkit-center" }}>
                    <img
                      className="h-56 w-auto rounded-lg mb-4"
                      src={card.cardPicture}
                      alt=""
                    />
                  </div>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Serial Number
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {card.serialNumber}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Coin Price
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {card.price}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Currency of Card
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {card.currency}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Amount of Card
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {card.amount}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Store Name
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {Capitalize(card.storeName)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Store Address
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {Capitalize(card.storeAddress)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Valid Till
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {new Date(card.validTill).toDateString()}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Expiry Duration (Minutes)
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {card.expiryDuration}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Expired
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {card.isExpired ? "Yes" : "No"}
                      </dd>
                    </div>
                  </dl>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
