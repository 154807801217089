import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Logo from "../../assets/img/wander logo without bg black.png";
import Input from "../../components/common/InputField";
import Spinner from "../../components/common/Spinner";
import axios from "../../components/common/axios";
import { MailIcon } from "@heroicons/react/outline";

const ForgotPassword = ({ className, ...rest }) => {
  // const { login } = useAuth();
  const [sent, setSent] = useState(false);

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-10 w-auto" src={Logo} alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Forgot Password
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {sent ? (
            <div
              class="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"
              role="alert"
            >
              <span class="font-medium">Email sent successfully.</span> Please
              check your email and click on the provided link to reset your
              password.
            </div>
          ) : (
            <p className="text-sm">
              Please enter your email address to search for your account.
            </p>
          )}
          <Formik
            initialValues={{
              email: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting, setFieldValue }
            ) => {
              try {
                setSubmitting(true);
                const response = await axios.post("auth/forgot_password", {
                  email: values.email,
                });

                setStatus({ success: true });
                setSubmitting(false);
                setSent(true);
                setFieldValue("email", "");
              } catch (err) {
                console.error(err.message);
                setStatus({ success: false });
                setErrors({
                  submit: err.response
                    ? err.response.data.message
                    : "Something went wrong. Please try again later.",
                });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  label="Email address"
                  placeholder="Email address"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <div>
                  <p className="text-red-500 mb-2 text-10">{errors.submit}</p>
                  {isSubmitting ? (
                    <button className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <Spinner size={4} /> Sending Email...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Send Email
                    </button>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
