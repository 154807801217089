import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Logo from "../../assets/img/wander logo without bg black.png";
import Input from "../../components/common/InputField";
import Spinner from "../../components/common/Spinner";
import axios from "../../components/common/axios";
import query from "query-string";
import { toast } from "react-toastify";
import { withRouter } from "react-router";

const ResetPassword = ({ history, ...rest }) => {
  useEffect(() => {
    const { token } = query.parse(history.location.search);
    if (!token) {
      history.push("/login");
    }
  }, []);

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-10 w-auto" src={Logo} alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Reset Password
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Formik
            initialValues={{
              newPassword: "",
              confirmNewPassword: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              newPassword: Yup.string()
                .min(6)
                .max(255)
                .required("New Password is required."),
              confirmNewPassword: Yup.string()
                .min(6)
                .max(255)
                .required("New Password is required.")
                .oneOf([Yup.ref("newPassword")], "Passwords does not match"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const { token } = query.parse(history.location.search);
                setSubmitting(true);
                const response = await axios.post("auth/reset_password", {
                  password: values.newPassword,
                  temp_code: token,
                });

                setStatus({ success: true });
                setSubmitting(false);
                toast.success(response.data.message);
                history.push("/login");
              } catch (err) {
                console.error(err.message);
                setStatus({ success: false });
                setErrors({
                  submit: err.response
                    ? err.response.data.message
                    : "Something went wrong. Please try again later.",
                });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  label="New Password"
                  placeholder="New Password"
                  autoComplete="newPassword"
                  value={values.newPassword}
                  onChange={handleChange}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  helperText={touched.newPassword && errors.newPassword}
                />
                <Input
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  type="password"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  autoComplete="confirmNewPassword"
                  value={values.confirmNewPassword}
                  onChange={handleChange}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  error={Boolean(
                    touched.confirmNewPassword && errors.confirmNewPassword
                  )}
                  helperText={
                    touched.confirmNewPassword && errors.confirmNewPassword
                  }
                />
                <div>
                  <p className="text-red-500 mb-2 text-10">{errors.submit}</p>
                  {isSubmitting ? (
                    <button className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <Spinner size={4} /> Saving...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Reset
                    </button>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ResetPassword);
