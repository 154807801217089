import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, withRouter } from "react-router-dom";
import query from "query-string";
import { toast } from "react-toastify";
import Input from "../../components/common/InputField";
import PictureDrag from "../../components/common/PictureDrag";
import axios from "../../components/common/axios";
import axio from "axios";
import useAuth from "../../hooks/useAuth";
import Spinner from "../../components/common/Spinner";

const UpdateCard = ({ className, history, ...rest }) => {
  const { user, logout } = useAuth();
  const [isLoading, setIsLoading] = useState({});
  const [giftcard, setGiftCard] = useState({});
  useEffect(() => {
    getGiftCard();
  }, []);

  const getGiftCard = async () => {
    try {
      const { id } = query.parse(history.location.search);
      if (!id) {
        history.goBack();
      }
      setIsLoading(true);
      const response = await axios.get(`/giftcard/${id}`, {
        headers: {
          Authorization: user.token,
        },
      });
      setIsLoading(false);

      setGiftCard({ ...response.data.body[0] });
    } catch (err) {
      console.error(err.message);
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          toast.error("Session expired. Please login again.");
          await logout();
          return;
        }
        toast.error(err.response.data.message);
      }
    }
  };

  return (
    <>
      <div className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Update Gift Card
            </h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {/* <Link to="/card-list"> */}
            <button
              type="button"
              onClick={() => history.goBack()}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-500 px-4 py-2 my-3 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Cancel
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center sm:px-6 lg:px-8">
        <div className="mt-4">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Formik
              enableReinitialize={true}
              initialValues={{
                serialNumber: giftcard.serialNumber || "",
                amount: giftcard.amount || "",
                currency: giftcard.currency || "",
                storeName: giftcard.storeName || "",
                storeAddress: giftcard.storeAddress || "",
                cardPicture: giftcard.cardPicture || "",
                validTill: giftcard.validTill
                  ? new Date(giftcard.validTill).toISOString().split("T")[0]
                  : "",
                expiryDuration: giftcard.expiryDuration || "",
                coinPrice: giftcard.price || "",
                controlCode: giftcard.controlCode || "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                serialNumber: Yup.string().required("Serial No is required"),
                amount: Yup.number()
                  .typeError("Only numbers are allowed for this field.")
                  .required("Amount is required"),
                currency: Yup.string()
                  .matches(
                    /^[aA-zZ\s]+$/,
                    "Only alphabets are allowed for this field."
                  )
                  .required("Currency is required"),
                storeName: Yup.string().required("Store Name is required"),
                storeAddress: Yup.string().required(
                  "Store Address is required"
                ),
                cardPicture: Yup.string().required("Card Picture is required"),
                validTill: Yup.date()
                  .min(
                    new Date().toISOString().split("T")[0],
                    "Date can not be from past."
                  )
                  .required("Valid Till is required"),
                expiryDuration: Yup.number()
                  .typeError("Only numbers are allowed for this field.")
                  .required("Expiry Duration is required"),
                coinPrice: Yup.number()
                  .typeError("Only numbers are allowed for this field.")
                  .required("Coin Price is required"),
                controlCode: Yup.string().required("Control Code is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  setSubmitting(true);

                  let fileUrl = values.cardPicture;
                  if (typeof values.cardPicture != "string") {
                    let signedUrl = await axios.post(
                      "/getPresignedUrl",
                      {
                        name:
                          new Date().toTimeString() + values.cardPicture.name,
                        dict: "gift_card_images",
                        dataType: values.cardPicture.type,
                      },
                      {
                        headers: {
                          Authorization: user.token,
                        },
                      }
                    );
                    signedUrl = signedUrl.data.url;
                    await axio.put(signedUrl, values.cardPicture, {
                      headers: { "Content-Type": values.cardPicture.type },
                    });
                    fileUrl = signedUrl.slice(
                      0,
                      signedUrl.indexOf("?Content-Type")
                    );
                    if (!signedUrl) {
                      toast.error(
                        "Something went went while uploading the image. Please try again later!"
                      );
                      return;
                    }
                  }

                  const { id } = query.parse(history.location.search);
                  const response = await axios.put(
                    "/giftcard",
                    {
                      updateId: id,
                      serialNumber: values.serialNumber,
                      amount: values.amount,
                      currency: values.currency,
                      storeName: values.storeName,
                      storeAddress: values.storeAddress,
                      cardPicture: fileUrl,
                      validTill: values.validTill,
                      expiryDuration: values.expiryDuration,
                      price: values.coinPrice,
                      controlCode: values.controlCode,
                    },
                    {
                      headers: {
                        Authorization: user.token,
                      },
                    }
                  );

                  if (response.status != 200) {
                    if (response.data.errors && response.data.errors.length) {
                      for (let error of response.data.errors) {
                        toast.error(error);
                      }
                    } else {
                      toast.error(response.data.message);
                    }
                    return;
                  }

                  setStatus({ success: true });
                  toast.success(response.data.message);
                  setSubmitting(false);
                  history.push("/card-list");
                } catch (err) {
                  console.error(err.message);
                  if (err.response) {
                    if (err.response.data.message === "Unauthorized") {
                      toast.error("Session expired. Please login again.");
                      await logout();
                      return;
                    }
                    toast.error(err.response.data.message);
                  }
                  setStatus({ success: false });
                  setErrors({
                    submit: err.response
                      ? err.response.data.message
                      : "Something went wrong. Please try again later.",
                  });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {isLoading ? (
                    <div
                      style={{
                        textAlign: "-webkit-center",
                      }}
                    >
                      <Spinner size={10} />
                    </div>
                  ) : (
                    <>
                      <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-3">
                        <Input
                          id="serialNumber"
                          name="serialNumber"
                          type="text"
                          label="Serial No"
                          autoComplete="serialNumber"
                          value={values.serialNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(
                            touched.serialNumber && errors.serialNumber
                          )}
                          helperText={
                            touched.serialNumber && errors.serialNumber
                          }
                        />
                        <Input
                          id="coinPrice"
                          name="coinPrice"
                          type="number"
                          min={0}
                          label="Coin Price"
                          autoComplete="coinPrice"
                          value={values.coinPrice}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(touched.coinPrice && errors.coinPrice)}
                          helperText={touched.coinPrice && errors.coinPrice}
                        />
                        <Input
                          id="currency"
                          name="currency"
                          type="text"
                          label="Currency of Card"
                          autoComplete="currency"
                          value={values.currency}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(touched.currency && errors.currency)}
                          helperText={touched.currency && errors.currency}
                        />
                        <Input
                          id="amount"
                          name="amount"
                          type="number"
                          min={0}
                          label="Amount of Card"
                          autoComplete="amount"
                          value={values.amount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(touched.amount && errors.amount)}
                          helperText={touched.amount && errors.amount}
                        />
                        <Input
                          id="storeName"
                          name="storeName"
                          type="text"
                          label="Store Name"
                          autoComplete="storeName"
                          value={values.storeName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(touched.storeName && errors.storeName)}
                          helperText={touched.storeName && errors.storeName}
                        />
                        <Input
                          id="storeAddress"
                          name="storeAddress"
                          type="text"
                          label="Store Address"
                          autoComplete="storeAddress"
                          value={values.storeAddress}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(
                            touched.storeAddress && errors.storeAddress
                          )}
                          helperText={
                            touched.storeAddress && errors.storeAddress
                          }
                        />
                        <Input
                          id="validTill"
                          name="validTill"
                          type="date"
                          label="Valid Till"
                          value={values.validTill}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          min={new Date().toISOString().split("T")[0]}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(touched.validTill && errors.validTill)}
                          helperText={touched.validTill && errors.validTill}
                        />
                        <Input
                          id="expiryDuration"
                          name="expiryDuration"
                          type="number"
                          min={0}
                          pattern="\d*"
                          maxLength="4"
                          label="Expiry Duration (Minutes)"
                          autoComplete="expiryDuration"
                          value={values.expiryDuration}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(
                            touched.expiryDuration && errors.expiryDuration
                          )}
                          helperText={
                            touched.expiryDuration && errors.expiryDuration
                          }
                        />
                        <Input
                          id="controlCode"
                          name="controlCode"
                          type="text"
                          label="Control Code"
                          autoComplete="controlCode"
                          value={values.controlCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(
                            touched.controlCode && errors.controlCode
                          )}
                          helperText={touched.controlCode && errors.controlCode}
                        />
                      </div>
                      <PictureDrag
                        value={values.cardPicture}
                        onChange={(e) => {
                          if (!e.target.files) return;
                          setFieldValue("cardPicture", e.target.files[0]);
                        }}
                      />
                      {errors.cardPicture && (
                        <p className="text-red-500 text-10 ml-2">
                          {errors.cardPicture}
                        </p>
                      )}
                      {errors.submit && (
                        <p className="text-red-500 text-10 ml-2">
                          {errors.submit}
                        </p>
                      )}
                      <div className="text-right">
                        {isSubmitting ? (
                          <button
                            disabled={true}
                            className="inline-flex items-center disabled:bg-gray-300 justify-center rounded-md border border-transparent bg-purple px-4 py-2 my-3 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                          >
                            <Spinner size={4} /> Updating...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-purple px-4 py-2 my-3 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                          >
                            Update Gift Card
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(UpdateCard);
