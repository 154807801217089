import shortId from "shortid";

// export const BASEURL =
//   "https://zsklr6boql.execute-api.eu-north-1.amazonaws.com/master";
export const BASEURL =
  "https://skzjifm0ll.execute-api.eu-north-1.amazonaws.com/dev";
// export const BASEURL = "http://localhost:3000/dev";

export const GoogleApiKey = "AIzaSyDtak3ilLMBQtICt1NJjeNozbqfd3QDnfs";

export const QUIZOPTIONS = [
  { id: 1, label: "Option 1", value: "option1" },
  { id: 2, label: "Option 2", value: "option2" },
  { id: 3, label: "Option 3", value: "option3" },
  { id: 4, label: "Option 4", value: "option4" },
];

export const TRACKINGOPTIONS = [
  { label: "Enable", value: true },
  { label: "Disable", value: false },
];

export const LANGUAGES = [
  { id: 1, label: "English", value: "en" },
  { id: 2, label: "Swedish", value: "sv" },
];

export const CHARTFILTER = [
  { id: 1, label: "Today", value: "Today" },
  { id: 2, label: "Last Week", value: "Week" },
  { id: 3, label: "Month", value: "Month" },
  { id: 4, label: "Year", value: "Year" },
  { id: 5, label: "All Time", value: "All" },
];

export const GENDER = [
  { id: 1, label: "Male", value: "male" },
  { id: 2, label: "Female", value: "female" },
];

export const USERROLES = [
  { id: 1, label: "Admin", value: "Admin" },
  { id: 2, label: "User", value: "User" },
];

export const STATUS = [
  { id: 1, label: "Active ", value: true },
  { id: 2, label: "Inactive", value: false },
];

export const FILTERARRAY = [
  {
    type: "text",
    value: "",
    show: [
      //#region user input filters
      {
        id: shortId.generate(),
        module: "users",
        name: "name",
        placeholder: "User Name",
        label: "User Name",
      },
      {
        id: shortId.generate(),
        module: "users",
        name: "email",
        placeholder: "Email",
        label: "Email",
      },
      //#endregion
      //#region quiz input filters
      {
        id: shortId.generate(),
        module: "quiz",
        name: "question",
        placeholder: "Question",
        label: "Question",
      },
      //#endregion
      //#region gift cards input filters
      //gift cards
      {
        id: shortId.generate(),
        module: "giftcards",
        name: "serialNumber",
        placeholder: "Serial Number",
        label: "Serial Number",
      },
      {
        id: shortId.generate(),
        module: "giftcards",
        name: "storeName",
        placeholder: "Store Name",
        label: "Store Name",
      },
      //#endregion
      //#region gift boxes input filters
      {
        id: shortId.generate(),
        module: "giftbox",
        name: "type",
        placeholder: "Config Name",
        label: "Config Name",
      },
      //#endregion
      //#region Complaint queries input filters
      {
        id: shortId.generate(),
        module: "queries",
        name: "title",
        placeholder: "Title",
        label: "Title",
      },
      //#endregion
      //#region notifications input filters
      {
        id: shortId.generate(),
        module: "notifications",
        name: "title",
        placeholder: "Title",
        label: "Title",
      },
      {
        id: shortId.generate(),
        module: "notifications",
        name: "message",
        placeholder: "Message",
        label: "Message",
      },
      //#endregion
    ],
  },
  {
    type: "select",
    show: [
      //#region gift cards input filters
      {
        id: shortId.generate(),
        module: "giftcards",
        name: "isExpired",
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
        placeholder: "Is Expired?",
        label: "Is Expired?",
      },
      //#endregion
      //#region Complaint queries input filters
      {
        id: shortId.generate(),
        module: "queries",
        name: "isRead",
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
        placeholder: "Is Read?",
        label: "Is Read?",
      },
      //#endregion
    ],
  },
];
