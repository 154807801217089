import React from "react";
import { Redirect } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const AuthGuard = (props) => {
  const { isAuth } = useAuth();

  if (!isAuth) {
    return <Redirect to="/login" />;
  }

  return <>{props.children}</>;
};

// AuthGuard.propTypes = {
//   children: PropTypes.node,
//   permission: PropTypes.string,
// };

export default AuthGuard;
