import React from "react";
import CSVImage from "../../assets/img/csv.png";

const PictureDrag = ({
  label,
  className,
  value,
  onChange,
  error,
  helperText,
  title,
  fileTypes,
  typesText,
  ...rest
}) => {
  return (
    <div className="mt-4">
      <div className="col-span-3">
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="mt-1 border-2 border-gray-300 border-dashed rounded-md px-6 pt-5 pb-6 flex justify-center">
          <div className="space-y-1 text-center">
            {value ? (
              <div className="my-2">
                <img
                  src={
                    typeof value == "string"
                      ? value
                      : value.type === "text/csv"
                      ? CSVImage
                      : URL.createObjectURL(value)
                  }
                  alt=""
                  className="w-28 mx-auto"
                />
                {value.type === "text/csv" ? (
                  <p className="text-xs text-gray-600">{value.name}</p>
                ) : null}
              </div>
            ) : (
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}

            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <span>{title || "Upload a file"}</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  accept={fileTypes || ""}
                  onChange={onChange}
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500">
              {typesText || "PNG, JPG, GIF"} up to 10MB
            </p>
          </div>
        </div>
      </div>
      {error ? (
        <p className="mb-0 text-red-600 text-body-3xs ml-2">{helperText}</p>
      ) : null}
    </div>
  );
};

export default PictureDrag;
