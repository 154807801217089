import React, { useState } from "react";
import { Route } from "react-router-dom";
import WithSidebar from "../layouts/WithSidebar";
import WithoutSidebar from "../layouts/WithoutSidebar";

const PublicRoute = (props) => {
  const { component, withSidebar, ...rest } = props;
  const [sidebarOpen, setSideBarOpen] = useState(false);

  const renderComponentWithSidebarOrNot = (prop, C) => {
    if (withSidebar) {
      return (
        <props.guard>
          <WithSidebar
            sidebarOpen={sidebarOpen}
            setSideBarOpen={setSideBarOpen}
            {...props}
          >
            <C {...{ ...prop, ...rest }} />
          </WithSidebar>
        </props.guard>
      );
    }

    return (
      <props.guard>
        <WithoutSidebar {...props}>
          <C {...{ ...prop, ...rest }} />
        </WithoutSidebar>
      </props.guard>
    );
  };

  return (
    <Route
      {...rest}
      render={(prop) => renderComponentWithSidebarOrNot(prop, component)}
    />
  );
};

export default PublicRoute;
