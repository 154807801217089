import { useHistory } from "react-router";
import {
  BellIcon,
  ChatIcon,
  ClipboardListIcon,
  CreditCardIcon,
  GiftIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { ReactComponent as HomeIcon } from "../../assets/img/home-icon.svg";

const nav = [
  { name: "Home", to: "/", icon: HomeIcon, authProtected: true },
  {
    name: "User",
    to: "/user-list",
    icon: UserIcon,
    authProtected: true,
  },
  {
    name: "Quiz Questions",
    to: "/quiz-list",
    icon: ClipboardListIcon,
    authProtected: true,
  },
  {
    name: "Gift Cards",
    to: "/card-list",
    icon: CreditCardIcon,
    authProtected: true,
  },
  {
    name: "Push Notifications",
    to: "/notification-list",
    icon: BellIcon,
    authProtected: true,
  },
  {
    name: "Complaint Queries",
    to: "/query-list",
    icon: ChatIcon,
    authProtected: true,
  },
  {
    name: "Gift Box Config",
    to: "/box-list",
    icon: GiftIcon,
    authProtected: true,
  },
];

const Navigation = () => {
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  return nav.map((m) => ({ ...m, current: m.to === pathname }));
};

export default Navigation;
