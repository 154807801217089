import React from "react";

const Input = ({
  label,
  labelClassname,
  className,
  value,
  onChange,
  onBlur,
  name,
  id,
  type,
  required,
  autoComplete,
  error,
  helperText,
  max,
  maxLength,
  min,
  minLength,
  pattern,
  multiple,
  inputRef,
  ...rest
}) => {
  return (
    <div className="mt-4">
      <label
        htmlFor={name}
        className={`${
          labelClassname || ""
        } block text-sm font-medium text-gray-700`}
      >
        {label}
        {required ? <span className="text-red-600">*</span> : null}
      </label>
      <div className="mt-1">
        <input
          id={id}
          ref={inputRef}
          name={name}
          type={type}
          min={min}
          minLength={minLength}
          max={max}
          pattern={pattern}
          maxLength={maxLength}
          multiple={multiple}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          className={className}
          {...rest}
        />
      </div>
      {error ? (
        <p className="mb-0 text-red-600 text-body-3xs ml-2">{helperText}</p>
      ) : null}
    </div>
  );
};

export default Input;
