import React, { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import SplashScreen from "../components/common/Loading";
import { BASEURL } from "../constants";

const initialAuthState = {
  isAuth: false,
  isInitialised: false,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuth, user } = action.payload;

      return {
        ...state,
        isAuth,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuth: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuth: false,
        user: null,
      };
    }
    case "UPDATEPROFILE": {
      window.localStorage.setItem(
        "UserV3",
        JSON.stringify({ ...state.user, ...action.payload })
      );
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuth: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  updateProfile: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email, password) => {
    const response = await axios.post(
      `${BASEURL}/auth/login`,
      {
        email,
        password,
        adminPortal: true,
      },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    if (response.data.user.roleName == "User") {
      throw new Error("Only admin can login here.");
    }

    window.localStorage.setItem(
      "UserV3",
      JSON.stringify({ ...response.data.user, token: response.data.token })
    );
    dispatch({
      type: "LOGIN",
      payload: {
        user: { ...response.data.user, token: response.data.token },
      },
    });
    return response;
  };

  const logout = async () => {
    try {
      // const response = await axios.get(`${BASEURL}/auth/logout`, {
      //   headers: {
      //     "Content-type": "application/json",
      //     Authorization: state.user.token,
      //   },
      // });

      window.localStorage.removeItem("UserV3");
      window.localStorage.clear();
      dispatch({ type: "LOGOUT" });
    } catch (error) {
      console.error(error);
      window.localStorage.removeItem("UserV3");
      window.localStorage.clear();
      dispatch({ type: "LOGOUT" });
    }
  };

  const updateProfile = (data) => {
    dispatch({ type: "UPDATEPROFILE", payload: data });
  };

  const register = async (email, name, password) => {
    const response = await axios.post("/auth/register", {
      email,
      name,
      password,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };
  const getUser = () => {
    return JSON.parse(window.localStorage.getItem("UserV3"));
  };
  useEffect(() => {
    const initialise = async () => {
      try {
        const user = JSON.parse(window.localStorage.getItem("UserV3"));
        if (user) {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuth: true,
              user: user,
            },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuth: false,
              user: null,
            },
          });
          window.localStorage.clear();
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuth: false,
            user: null,
          },
        });
        window.localStorage.clear();
      }
    };
    initialise();
  }, []);
  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
        getUser,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
