import React from "react";
import GuestGuard from "../components/GuestGuard";
import AuthGuard from "../components/AuthGuard";
import UpdateCard from "../views/GiftCard/UpdateCard";
import UpdateUser from "../views/Users/UpdateUser";
import Profile from "../views/Profile/UpdateUser";
import UserDetails from "../views/Users/UserDetails";
import QueryDetails from "../views/Queries/QueryDetails";
import AddMultipleCard from "../views/GiftCard/AddMultipleCard";
import AddUser from "../views/Users/AddUser";
import ForgotPassword from "../views/Auth/ForgotPassword";
import ResetPassword from "../views/Auth/ResetPassword";
import VerifyAccount from "../views/Auth/VerifyAccount";

const Home = React.lazy(() => import("../views/Home/Home"));
const Login = React.lazy(() => import("../views/Auth/Login"));
const QuizList = React.lazy(() => import("../views/Quiz/QuizList"));
const AddQuiz = React.lazy(() => import("../views/Quiz/AddQuiz"));
const EditQuiz = React.lazy(() => import("../views/Quiz/UpdateQuiz"));
const CardList = React.lazy(() => import("../views/GiftCard/CardList"));
const AddCard = React.lazy(() => import("../views/GiftCard/AddCard"));
const NotificationList = React.lazy(() =>
  import("../views/PushNotifications/NotificationList")
);
const AddNotification = React.lazy(() =>
  import("../views/PushNotifications/AddNotification")
);
const QueryList = React.lazy(() => import("../views/Queries/QueryList"));
const UserList = React.lazy(() => import("../views/Users/UserList"));
const BoxList = React.lazy(() => import("../views/GiftBox/BoxList"));
const AddBox = React.lazy(() => import("../views/GiftBox/AddBox"));
const AddBoxManual = React.lazy(() => import("../views/GiftBox/AddBoxManual"));
const UpdateBox = React.lazy(() => import("../views/GiftBox/UpdateBox"));
const ErrorPage = React.lazy(() => import("../views/ErrorPage"));

const routes = [
  {
    path: "/",
    pageName: "Home",
    exact: true,
    withSidebar: true,
    component: Home,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/quiz-list",
    pageName: "Quiz List",
    exact: true,
    withSidebar: true,
    component: QuizList,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/add-quiz",
    pageName: "Add Quiz",
    exact: true,
    withSidebar: true,
    component: AddQuiz,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/update-quiz",
    pageName: "Update Quiz",
    exact: true,
    withSidebar: true,
    component: EditQuiz,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/card-list",
    pageName: "Card List",
    exact: true,
    withSidebar: true,
    component: CardList,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/add-card",
    pageName: "Add Card",
    exact: true,
    withSidebar: true,
    component: AddCard,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/add-multiple-card",
    pageName: "Add Multiple Card",
    exact: true,
    withSidebar: true,
    component: AddMultipleCard,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/update-card",
    pageName: "Update Card",
    exact: true,
    withSidebar: true,
    component: UpdateCard,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/box-list",
    pageName: "Box List",
    exact: true,
    withSidebar: true,
    component: BoxList,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/add-box",
    pageName: "Add Box",
    exact: true,
    withSidebar: true,
    component: AddBox,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/update-box",
    pageName: "Update Box",
    exact: true,
    withSidebar: true,
    component: UpdateBox,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/add-box-manual",
    pageName: "Add Box Manual",
    exact: true,
    withSidebar: true,
    component: AddBoxManual,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/notification-list",
    pageName: "Notification List",
    exact: true,
    withSidebar: true,
    component: NotificationList,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/add-notification",
    pageName: "Add Notification",
    exact: true,
    withSidebar: true,
    component: AddNotification,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/query-list",
    pageName: "Query List",
    exact: true,
    withSidebar: true,
    component: QueryList,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/query-details",
    pageName: "Query Details",
    exact: true,
    withSidebar: true,
    component: QueryDetails,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/user-list",
    pageName: "User List",
    exact: true,
    withSidebar: true,
    component: UserList,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/add-user",
    pageName: "Add User",
    exact: true,
    withSidebar: true,
    component: AddUser,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/update-user",
    pageName: "Update User",
    exact: true,
    withSidebar: true,
    component: UpdateUser,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/user-details",
    pageName: "User Details",
    exact: true,
    withSidebar: true,
    component: UserDetails,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/profile",
    pageName: "Profile",
    exact: true,
    withSidebar: true,
    component: Profile,
    guard: AuthGuard,
    title: "",
    subtitle: "",
  },
  {
    path: "/login",
    pageName: "Login",
    exact: true,
    guard: GuestGuard,
    component: Login,
    title: "",
    subtitle: "",
  },
  {
    path: "/forgot-password",
    pageName: "Forgot Password",
    exact: true,
    guard: GuestGuard,
    component: ForgotPassword,
    title: "",
    subtitle: "",
  },
  {
    path: "/verify-account",
    pageName: "Verify Account",
    exact: true,
    guard: GuestGuard,
    component: VerifyAccount,
    title: "",
    subtitle: "",
  },
  {
    path: "/reset-password",
    pageName: "Reset Password",
    exact: true,
    guard: GuestGuard,
    component: ResetPassword,
    title: "",
    subtitle: "",
  },
  {
    path: "",
    pageName: "Error",
    withSidebar: false,
    component: ErrorPage,
    title: "404",
    bgImg: "md:bg-commonSectionBg",
    mobileBgImg: "bg-Mobile404Bg",
    bgImgClass: "bg-100% bg-no-repeat bg-bottom",
  },
];

export default routes;
