import React, { useEffect, useState } from "react";
import Logo from "../../assets/img/wander logo without bg black.png";
import Spinner from "../../components/common/Spinner";
import axios from "../../components/common/axios";
import query from "query-string";
import { withRouter } from "react-router";

const VerifyAccount = ({ history, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("Account verified successfully!");

  useEffect(() => {
    const { token } = query.parse(history.location.search);
    if (!token) {
      history.push("/login");
    } else {
      verifyToken(token);
    }
  }, []);

  const verifyToken = async (token) => {
    try {
      setIsLoading(true);
      const response = await axios.post("auth/verifyToken", {
        temp_code: token,
      });

      setIsLoading(false);
      setMessage(response.data.message);
    } catch (err) {
      console.error(err.message);
      setIsLoading(false);
      setMessage(
        err.response
          ? err.response.data.message
          : "Something went wrong. Please try again later."
      );
    }
  };

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-10 w-auto" src={Logo} alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Verify Account
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div
          style={{
            textAlign: "-webkit-center",
          }}
        >
          {isLoading ? (
            <Spinner colorClass="text-purple" />
          ) : (
            <>
              <h2 className="text-2xl font-bold text-center text-purple-700">
                {message}
              </h2>
              <button
                onClick={() => {
                  history.push("/login");
                }}
                className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Go To Login
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(VerifyAccount);
