import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, withRouter } from "react-router-dom";
import axio from "axios";
import Input from "../../components/common/InputField";
import Select from "../../components/common/Select";
import axios from "../../components/common/axios";
import useAuth from "../../hooks/useAuth";
import Spinner from "../../components/common/Spinner";
import { GENDER, STATUS } from "../../constants";
import PictureDrag from "../../components/common/PictureDrag";
import { toast } from "react-toastify";

const Profile = ({ className, history, ...rest }) => {
  const { user, updateProfile, logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUser] = useState([]);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`/users/${user.id}`, {
        headers: {
          Authorization: user.token,
        },
      });
      setIsLoading(false);

      setUser({ ...response.data.body });
    } catch (err) {
      console.error(err.message);
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          toast.error("Session expired. Please login again.");
          await logout();
          return;
        }
        toast.error(err.response.data.message);
      }
    }
  };

  return (
    <>
      <div className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Profile</h1>
            {/* <p className="mt-2 text-sm text-gray-700">
            A list of all the quiz questions in your account including their name, title, email and role.
          </p> */}
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {/* <Link to="/"> */}
            <button
              type="button"
              onClick={() => history.goBack()}
              className={
                "inline-flex items-center justify-center rounded-md border border-transparent bg-gray-500 px-4 py-2 my-3 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              }
            >
              Cancel
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
      <div className="sm:px-6 lg:px-8">
        <div className="mt-4">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <h2 className="text-xl font-semibold text-gray-900">
              Personal Information
            </h2>
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: users.name || "",
                dob: users.dob
                  ? new Date(users.dob).toISOString().split("T")[0]
                  : "",
                profilePic: users.profilePic || "",
                height: users.height || "0",
                weight: users.weight || "0",
                gender: GENDER.find((item) => item.value == users.gender) || "",
                totalCoins: users.totalCoins || "0",
                dailyFSGoal: users.dailyFSGoal || "0",
                isActive:
                  STATUS.find((item) => item.value == users.isActive) || "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .matches(
                    /^[aA-zZ\s]+$/,
                    "Only alphabets are allowed for this field "
                  )
                  .required("Name is required"),
                dob: Yup.date().max(
                  new Date().toISOString().split("T")[0],
                  "Date can not be from future."
                ),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  setSubmitting(true);

                  let fileUrl = values.profilePic;
                  if (typeof values.profilePic != "string") {
                    let signedUrl = await axios.post(
                      "/getPresignedUrl",
                      {
                        name:
                          new Date().toTimeString() + values.profilePic.name,
                        dict: "profile_Images",
                        dataType: values.profilePic.type,
                      },
                      {
                        headers: {
                          Authorization: user.token,
                        },
                      }
                    );

                    signedUrl = signedUrl.data.url;

                    await axio.put(signedUrl, values.profilePic, {
                      headers: { "Content-Type": values.profilePic.type },
                    });

                    fileUrl = signedUrl.slice(
                      0,
                      signedUrl.indexOf("?Content-Type")
                    );

                    if (!signedUrl) {
                      setErrors({
                        submit:
                          "Something went went while uploading the image. Please try again later!",
                      });
                      return;
                    }
                  }

                  const response = await axios.put(
                    "/profile",
                    {
                      updateId: user.id,
                      name: values.name,
                      dob: values.dob,
                      height: values.height,
                      weight: values.weight,
                      profilePic:
                        typeof values.profilePic != "string"
                          ? fileUrl
                          : values.profilePic,
                      gender: values.gender.value,
                      totalCoins: values.totalCoins,
                      dailyFSGoal: values.dailyFSGoal,
                      isActive: values.isActive.value,
                    },
                    {
                      headers: {
                        Authorization: user.token,
                      },
                    }
                  );
                  updateProfile({
                    name: values.name,
                    dob: values.dob,
                    height: values.height,
                    weight: values.weight,
                    profilePic:
                      typeof values.profilePic != "string"
                        ? fileUrl
                        : values.profilePic,
                    gender: values.gender.value,
                    totalCoins: values.totalCoins,
                    dailyFSGoal: values.dailyFSGoal,
                    isActive: values.isActive.value,
                  });
                  toast.success(response.data.message);
                  setStatus({ success: true });
                  setSubmitting(false);
                } catch (err) {
                  console.error(err.message);
                  if (err.response) {
                    if (err.response.data.message === "Unauthorized") {
                      toast.error("Session expired. Please login again.");
                      await logout();
                      return;
                    }
                    toast.error(err.response.data.message);
                  }
                  setStatus({ success: false });
                  setErrors({
                    submit: err.response
                      ? err.response.data.message
                      : "Something went wrong. Please try again later.",
                  });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {isLoading ? (
                    <div
                      style={{
                        textAlign: "-webkit-center",
                      }}
                    >
                      <Spinner size={10} />
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-3">
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          label="Name"
                          autoComplete="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                        <Input
                          id="height"
                          name="height"
                          type="number"
                          min={0}
                          label="Height"
                          autoComplete="height"
                          value={values.height}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(touched.height && errors.height)}
                          helperText={touched.height && errors.height}
                        />
                        <Input
                          id="weight"
                          name="weight"
                          type="number"
                          min={0}
                          label="Weight"
                          autoComplete="weight"
                          value={values.weight}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(touched.weight && errors.weight)}
                          helperText={touched.weight && errors.weight}
                        />
                        <Input
                          id="dob"
                          name="dob"
                          type="date"
                          label="DOB"
                          autoComplete="dob"
                          value={values.dob}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(touched.dob && errors.dob)}
                          helperText={touched.dob && errors.dob}
                        />
                        <Input
                          id="dailyFSGoal"
                          name="dailyFSGoal"
                          type="number"
                          min={0}
                          label="Daily FS Goal"
                          autoComplete="dailyFSGoal"
                          value={values.dailyFSGoal}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(
                            touched.dailyFSGoal && errors.dailyFSGoal
                          )}
                          helperText={touched.dailyFSGoal && errors.dailyFSGoal}
                        />
                        <Select
                          id="gender"
                          name="gender"
                          className={
                            "text-left appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          }
                          value={values.gender}
                          label="Gender"
                          onChange={(e) => {
                            setFieldValue("gender", e);
                          }}
                          options={GENDER}
                          error={Boolean(touched.gender && errors.gender)}
                          helperText={touched.gender && errors.gender}
                        />
                        <Select
                          id="isActive"
                          name="isActive"
                          className={
                            "text-left appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          }
                          value={values.isActive}
                          label="Status"
                          onChange={(e) => {
                            setFieldValue("isActive", e);
                          }}
                          options={STATUS}
                          required
                          error={Boolean(touched.isActive && errors.isActive)}
                          helperText={touched.isActive && errors.isActive}
                        />
                      </div>
                      <PictureDrag
                        title="Upload profile pic"
                        value={values.profilePic}
                        onChange={(e) => {
                          if (!e.target.files) return;
                          setFieldValue("profilePic", e.target.files[0]);
                        }}
                      />
                      {errors.submit && (
                        <p className="text-red-500 text-10 ml-2">
                          {errors.submit}
                        </p>
                      )}
                      <div className="text-right">
                        {isSubmitting ? (
                          <button
                            disabled={true}
                            className="inline-flex items-center disabled:bg-gray-300 justify-center rounded-md border border-transparent bg-purple px-4 py-2 my-3 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                          >
                            <Spinner size={4} /> Updating...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-purple px-4 py-2 my-3 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                          >
                            Update Profile
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
        <div className="mt-4">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <h2 className="text-xl font-semibold text-gray-900">
              Change Password
            </h2>
            <Formik
              initialValues={{
                oldPassword: "",
                newPassword: "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                oldPassword: Yup.string().required("Old Password is required"),
                newPassword: Yup.string().required("New Password is required"),
              })}
              onSubmit={async (
                values,
                {
                  setErrors,
                  setStatus,
                  setSubmitting,
                  setFieldValue,
                  setTouched,
                }
              ) => {
                try {
                  setSubmitting(true);
                  const response = await axios.post(
                    "/profile/change_password",
                    {
                      oldPassword: values.oldPassword,
                      newPassword: values.newPassword,
                    },
                    {
                      headers: {
                        Authorization: user.token,
                      },
                    }
                  );
                  setFieldValue("oldPassword", "");
                  setFieldValue("newPassword", "");
                  setTouched("oldPassword", false);
                  setTouched("newPassword", false);
                  toast.success(response.data.message);
                  setStatus({ success: true });
                  setSubmitting(false);
                } catch (err) {
                  console.error(err.message);
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {isLoading ? (
                    <div
                      style={{
                        textAlign: "-webkit-center",
                      }}
                    >
                      <Spinner size={10} />
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-3">
                        <Input
                          id="oldPassword"
                          name="oldPassword"
                          type="password"
                          label="Old Password"
                          autoComplete="oldPassword"
                          value={values.oldPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(
                            touched.oldPassword && errors.oldPassword
                          )}
                          helperText={touched.oldPassword && errors.oldPassword}
                        />
                        <Input
                          id="newPassword"
                          name="newPassword"
                          type="password"
                          label="New Password"
                          autoComplete="newPassword"
                          value={values.newPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          error={Boolean(
                            touched.newPassword && errors.newPassword
                          )}
                          helperText={touched.newPassword && errors.newPassword}
                        />
                      </div>
                      <div className="text-right">
                        {isSubmitting ? (
                          <button
                            disabled={true}
                            className="inline-flex items-center disabled:bg-gray-300 justify-center rounded-md border border-transparent bg-purple px-4 py-2 my-3 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                          >
                            <Spinner size={4} /> Updating...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-purple px-4 py-2 my-3 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                          >
                            Change Password
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Profile);
