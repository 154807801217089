import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../components/common/axios";
import axio from "axios";
import useAuth from "../../hooks/useAuth";
import PictureDrag from "../../components/common/PictureDrag";
import Papa from "papaparse";
import moment from "moment";
import { ErrorDetailsModal } from "../../components/common/ErrorDetailsModal";
import Spinner from "../../components/common/Spinner";
import { ImageModal } from "../../components/common/ImageModal";
import { ConfirmUploadModal } from "../../components/common/ConfirmUploadModal";

const AddMultipleCards = ({ history }) => {
  const { user, logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [giftcard, setGiftCard] = useState([]);
  const [openCard, setOpenCard] = useState(null);
  const [uploadFileId, setUploadFileId] = useState(null);
  const [file, setFile] = useState("");
  const [openImage, setOpenImage] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const updatingCard = (updateId, name, value) => {
    let tempCards = [...giftcard];
    const index = tempCards.findIndex((card) => card.id == updateId);

    tempCards[index][name] = value;

    setGiftCard(tempCards);
  };

  const uploadPicture = async (file, id) => {
    try {
      updatingCard(id, "updating", true);
      let signedUrl = await axios.post(
        "/getPresignedUrl",
        {
          name: new Date().toTimeString() + file.name,
          dict: "gift_card_images",
          dataType: file.type,
        },
        {
          headers: {
            Authorization: user.token,
          },
        }
      );

      signedUrl = signedUrl.data.url;
      await axio.put(signedUrl, file, {
        headers: { "Content-Type": file.type },
      });

      const fileUrl = signedUrl.slice(0, signedUrl.indexOf("?Content-Type"));

      if (!signedUrl) {
        toast.error(
          "Something went went while uploading the image. Please try again later!"
        );
        return;
      }

      updatingCard(id, "updating", false);
      updatingCard(id, "cardPicture", fileUrl);
    } catch (err) {
      console.error(err.message);
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          toast.error("Session expired. Please login again.");
          await logout();
          return;
        }
        toast.error(err.response.data.message);
      }
    }
  };

  const addGiftCards = async () => {
    try {
      setIsLoading(true);
      const filteredArr = giftcard.filter(
        (card) => !card.hasErrors && card.cardPicture
      );
      const response = await axios.post(
        "/multiplegiftcard",
        { giftCards: [...filteredArr] },
        {
          headers: {
            Authorization: user.token,
          },
        }
      );
      setIsLoading(false);
      toast.success(response.data.message);
      if (response.status === 201) {
        setGiftCard(response.data.body);
        return;
      }
      history.push("/card-list");
    } catch (err) {
      console.error(err.message);
      setIsLoading(false);
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          toast.error("Session expired. Please login again.");
          await logout();
          return;
        }

        if (err.response.status == "200") {
          setGiftCard([...err.response.data.body]);
        }
        toast.error(err.response.data.message);
      }
    }
  };

  const validateFields = (card, cardsArr) => {
    let errors = [];

    if (
      !card.serialNumber &&
      !card.controlCode &&
      !card.price &&
      !card.currency &&
      !card.amount &&
      !card.storeName &&
      !card.storeAddress &&
      !card.validTill &&
      !card.expiryDuration
    ) {
      return false;
    }

    if (!card.serialNumber) {
      //Validation on serial number
      errors.push("Serial number is required.");
    }
    if (!card.controlCode) {
      //Validation on Control Code
      errors.push("Control Code is required.");
    }

    if (card.serialNumber) {
      const sameSerialNumber = cardsArr.find(
        (item) => card.serialNumber === item.serialNumber
      );
      if (sameSerialNumber) {
        errors.push("Serial number should be unique.");
      }
    }
    if (card.controlCode) {
      const sameControlCode = cardsArr.find(
        (item) => card.controlCode === item.controlCode
      );
      if (sameControlCode) {
        errors.push("Control code should be unique.");
      }
    }
    //Validation on coin price
    if (!card.price || typeof +card.price !== "number") {
      errors.push("Coin Price should be a valid integer value.");
    }

    //Validation on currency
    if (!card.currency) {
      errors.push("Currency is required.");
    }

    //Validation on amount
    if (!card.amount || typeof +card.amount !== "number") {
      errors.push("Amount should be a valid integer value.");
    }

    //Validation on store name
    if (!card.storeName) {
      errors.push("Store Name is required.");
    }

    //Validation on store address
    if (!card.storeAddress) {
      errors.push("Store Address is required.");
    }

    //Validation on valid till
    if (
      !card.validTill ||
      !moment(card.validTill).isValid() ||
      moment(card.validTill).isBefore(new Date())
    ) {
      if (!card.validTill || !moment(card.validTill).isValid()) {
        errors.push("Valid till date is required.");
      } else if (moment(card.validTill).isBefore(new Date())) {
        errors.push("Valid till date can not be from past.");
      }
    }

    //Validation on expiry duration
    if (!card.expiryDuration || typeof +card.expiryDuration !== "number") {
      errors.push("Expiry Duration should be a valid integer value.");
    }

    return { ...card, hasErrors: errors.length ? true : false, errors };
  };

  const validateForm = (card) => {
    let tempCards = [...giftcard];

    let validatedCard = validateFields(
      card,
      tempCards.filter((item) => item.id != card.id)
    );

    const index = tempCards.findIndex((item) => item.id == card.id);

    tempCards[index] = { ...validatedCard };

    setGiftCard(tempCards);
    setOpenCard(null);
  };

  const parseFile = async (result) => {
    let tempCards = [];
    result.data.map((item, index) => {
      const card = validateFields(
        {
          id: index,
          serialNumber: item["Serial Number"],
          controlCode: item["Control Code"],
          price: item["Coin Price"],
          currency: item["Currency"],
          amount: item["Amount"],
          storeName: item["Store Name"],
          storeAddress: item["Store Address"],
          cardPicture: "",
          validTill: item["Valid Till"]
            ? moment(item["Valid Till"]).format("YYYY-MM-DD")
            : "",
          expiryDuration: item["Expiry Duration in Minutes"],
        },
        tempCards
      );

      if (!card) return;

      tempCards.push(card);
    });

    setGiftCard([...tempCards]);
  };

  return (
    <>
      <div className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Add Multiple Gift Cards
            </h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <a
              href="https://wander-public-images.s3.eu-north-1.amazonaws.com/gift_card_images/Multiple%20Gift%20Cards"
              download
            >
              <button
                type="button"
                className="mr-2 inline-flex items-center justify-center rounded-md border border-transparent bg-purple px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Download CSV Format
              </button>
            </a>
            <Link to="/card-list">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Cancel
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <PictureDrag
          title={"Upload CSV file"}
          fileTypes=".csv"
          typesText={"CSV"}
          value={file}
          onChange={(e) => {
            if (!e.target.files) return;
            setFile(e.target.files[0]);
            Papa.parse(e.target.files[0], {
              complete: parseFile,
              header: true,
            });
          }}
        />
        <div className="flex flex-col mt-2">
          <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
            {giftcard.length ? (
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th
                      className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      scope="col"
                    >
                      Card Image
                    </th>
                    <th
                      className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block"
                      scope="col"
                    >
                      Serial Number
                    </th>
                    <th
                      className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                      scope="col"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {giftcard.map((card) => (
                    <tr
                      key={card.id}
                      className={`${
                        !card.hasErrors && !card.cardPicture
                          ? "bg-yellow-200 text-yellow-500"
                          : (card.hasErrors && card.cardPicture) ||
                            card.hasErrors
                          ? "bg-red-300 text-red-500"
                          : " bg-green-300 text-green-500"
                      } bg-white`}
                    >
                      <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {card.updating ? (
                          <p className="flex text-gray-500">
                            <Spinner size={4} /> Uploading...
                          </p>
                        ) : (
                          <div className="flex items-center">
                            <span
                              className={`mr-1 text-xs ${
                                card.hasErrors
                                  ? "text-gray-500 cursor-not-allowed"
                                  : " hover:underline text-blue-600 hover:text-blue-500 cursor-pointer"
                              }`}
                              onClick={() => {
                                if (card.hasErrors) return;
                                document.getElementById("upload-image").click();
                                setUploadFileId(card.id);
                              }}
                            >
                              {card.cardPicture
                                ? "Change Image"
                                : "Upload Image"}
                            </span>
                            {card.cardPicture ? (
                              <img
                                src={card.cardPicture}
                                alt=""
                                width={40}
                                className="cursor-pointer h-6"
                                onClick={() => setOpenImage(card.cardPicture)}
                              />
                            ) : null}
                          </div>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <p
                          className={
                            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize"
                          }
                        >
                          {card.serialNumber}
                        </p>
                      </td>
                      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <p>
                          {!card.hasErrors && !card.cardPicture ? (
                            "Image Missing"
                          ) : (card.hasErrors && card.cardPicture) ||
                            card.hasErrors ? (
                            <span
                              className=" text-xs hover:underline text-red-600 hover:text-red-500 cursor-pointer"
                              onClick={() => {
                                setOpenCard(card);
                              }}
                            >
                              Show Errors
                            </span>
                          ) : (
                            "Successfull"
                          )}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </div>
          {giftcard.length ? (
            <div className="text-right my-3">
              {!giftcard.some((card) => card.cardPicture) ? (
                <p className="text-red-500 text-10 ml-2 mb-2">
                  Please upload images for the records otherwise "Create Gift
                  Cards" button will be disabled.
                </p>
              ) : null}
              <button
                disabled={
                  !giftcard.some(
                    (card) => !card.hasErrors && card.cardPicture
                  ) || isLoading
                }
                onClick={() => {
                  if (
                    giftcard.some((card) => card.hasErrors && !card.cardPicture)
                  ) {
                    setOpenConfirmation(true);
                    return;
                  }
                  addGiftCards();
                }}
                className="inline-flex items-center justify-center rounded-md border border-transparent disabled:bg-gray-400 bg-purple px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                {isLoading ? (
                  <>
                    <Spinner size={4} /> Creating...
                  </>
                ) : (
                  "Create Gift Cards"
                )}
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <input
        id="upload-image"
        type={"file"}
        onChange={(e) => {
          if (!e.target.files) return;
          uploadPicture(...e.target.files, uploadFileId);
          document.getElementById("upload-image").setAttribute("files", "");
        }}
        hidden
      />
      <ErrorDetailsModal
        card={openCard}
        setCard={setOpenCard}
        handleChange={updatingCard}
        validateForm={validateForm}
      />
      <ImageModal
        image={openImage}
        title="Card Image"
        setOpenImage={setOpenImage}
      />
      <ConfirmUploadModal
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        onConfirm={addGiftCards}
        onCancel={() => setOpenConfirmation(false)}
        uploading={isLoading}
      />
    </>
  );
};

export default withRouter(AddMultipleCards);
