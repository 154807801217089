/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon, XIcon } from "@heroicons/react/outline";
import { Capitalize } from "../../functions/utils";
import Input from "./InputField";

export const ErrorDetailsModal = ({
  card,
  setCard,
  handleChange,
  validateForm,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (card) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [card]);

  if (!card) return null;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setCard(null)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4 mt-3">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                    onClick={() => setCard(null)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start border-b pb-2">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mt-1"
                    >
                      Errors
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-4">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <Input
                        id="serialNumber"
                        name="serialNumber"
                        type="text"
                        label="Serial Number"
                        autoComplete="serialNumber"
                        value={card.serialNumber}
                        onChange={(e) => {
                          handleChange(card.id, "serialNumber", e.target.value);
                        }}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>{" "}
                    <div className="sm:col-span-1">
                      <Input
                        id="controlCode"
                        name="controlCode"
                        type="text"
                        label="Control Code"
                        autoComplete="controlCode"
                        value={card.controlCode}
                        onChange={(e) => {
                          handleChange(card.id, "controlCode", e.target.value);
                        }}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="sm:col-span-1">
                      <Input
                        id="price"
                        name="price"
                        type="number"
                        label="Coin Price"
                        autoComplete="price"
                        value={card.price}
                        onChange={(e) => {
                          handleChange(card.id, "price", e.target.value);
                        }}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="sm:col-span-1">
                      <Input
                        id="currency"
                        name="currency"
                        type="text"
                        label="Currency"
                        autoComplete="currency"
                        value={card.currency}
                        onChange={(e) => {
                          handleChange(card.id, "currency", e.target.value);
                        }}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="sm:col-span-1">
                      <Input
                        id="amount"
                        name="amount"
                        type="number"
                        label="Amount"
                        autoComplete="amount"
                        value={card.amount}
                        onChange={(e) => {
                          handleChange(card.id, "amount", e.target.value);
                        }}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="sm:col-span-1">
                      <Input
                        id="storeName"
                        name="storeName"
                        type="text"
                        label="Store Name"
                        autoComplete="storeName"
                        value={card.storeName}
                        onChange={(e) => {
                          handleChange(card.id, "storeName", e.target.value);
                        }}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="sm:col-span-1">
                      <Input
                        id="storeAddress"
                        name="storeAddress"
                        type="text"
                        label="Store Address"
                        autoComplete="storeAddress"
                        value={card.storeAddress}
                        onChange={(e) => {
                          handleChange(card.id, "storeAddress", e.target.value);
                        }}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="sm:col-span-1">
                      <Input
                        id="validTill"
                        name="validTill"
                        type="date"
                        label="Valid Till"
                        autoComplete="validTill"
                        min={new Date().toISOString().split("T")[0]}
                        value={card.validTill}
                        onChange={(e) => {
                          handleChange(card.id, "validTill", e.target.value);
                        }}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="sm:col-span-1">
                      <Input
                        id="expiryDuration"
                        name="expiryDuration"
                        type="number"
                        label="Expiry Duration in Minutes"
                        autoComplete="expiryDuration"
                        value={card.expiryDuration}
                        onChange={(e) => {
                          handleChange(
                            card.id,
                            "expiryDuration",
                            e.target.value
                          );
                        }}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </dl>
                  <div className="text-right">
                    <button
                      type="button"
                      onClick={() => {
                        validateForm(card);
                      }}
                      className="mr-2 mt-3 inline-flex items-center justify-center rounded-md border border-transparent bg-purple px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                      Save
                    </button>
                  </div>
                  {card.errors.length ? (
                    <React.Fragment>
                      <h1 className="py-3 border-t-2 mt-3 text-lg text-red-500">
                        Errors
                      </h1>
                      <ul className="mx-5">
                        {card.errors.map((err) => (
                          <li className="text-red-500 list-decimal">{err}</li>
                        ))}
                      </ul>
                    </React.Fragment>
                  ) : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
