import axio from "axios";
import { BASEURL } from "../../constants";

const axios = axio.create({ baseURL: BASEURL });

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
