import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import query from "query-string";
import Input from "../../components/common/InputField";
import Select from "../../components/common/Select";
import axios from "../../components/common/axios";
import useAuth from "../../hooks/useAuth";
import Spinner from "../../components/common/Spinner";
import { GENDER, TRACKINGOPTIONS, USERROLES } from "../../constants";
import { toast } from "react-toastify";

const AddUser = ({ className, history, ...rest }) => {
  const { user, logout } = useAuth();
  return (
    <>
      <div className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Add User</h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              onClick={() => history.goBack()}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-500 px-4 py-2 my-3 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center sm:px-6 lg:px-8">
        <div className="mt-4">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: "",
                email: "",
                height: "",
                weight: "",
                totalCoins: "",
                dailyFSGoal: "",
                gender: GENDER[0],
                role: USERROLES[0],
                isTracking: TRACKINGOPTIONS[1],
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .matches(
                    /^[aA-zZ\s]+$/,
                    "Only alphabets are allowed for this field "
                  )
                  .required("Name is required"),
                email: Yup.string()
                  .email("Please enter valid email")
                  .required("Email is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  setSubmitting(true);
                  const { id } = query.parse(history.location.search);
                  const response = await axios.post(
                    "/users",
                    {
                      name: values.name,
                      email: values.email,
                      height: values.height,
                      weight: values.weight,
                      gender: values.gender.value,
                      totalCoins: values.totalCoins,
                      dailyFSGoal: values.dailyFSGoal,
                      role: values.role.value,
                      isTracking: values.isTracking.value,
                    },
                    {
                      headers: {
                        Authorization: user.token,
                      },
                    }
                  );
                  toast.success(response.data.message);
                  setStatus({ success: true });
                  setSubmitting(false);
                  history.push(`/user-details?id=${response.data.body.id}`);
                } catch (err) {
                  console.error(err.message);
                  if (err.response) {
                    if (err.response.data.message === "Unauthorized") {
                      toast.error("Session expired. Please login again.");
                      await logout();
                      return;
                    }
                    toast.error(err.response.data.message);
                  }
                  setStatus({ success: false });
                  setErrors({
                    submit: err.response
                      ? err.response.data.message
                      : "Something went wrong. Please try again later.",
                  });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {" "}
                  <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-3">
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      label="Name"
                      autoComplete="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      label="Email"
                      autoComplete="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />

                    <Input
                      id="height"
                      name="height"
                      type="number"
                      min={0}
                      label="Height (cm)"
                      autoComplete="height"
                      value={values.height}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      error={Boolean(touched.height && errors.height)}
                      helperText={touched.height && errors.height}
                    />
                    <Input
                      id="weight"
                      name="weight"
                      type="number"
                      min={0}
                      label="Weight (Kg)"
                      autoComplete="weight"
                      value={values.weight}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      error={Boolean(touched.weight && errors.weight)}
                      helperText={touched.weight && errors.weight}
                    />
                    <Input
                      id="totalCoins"
                      name="totalCoins"
                      type="number"
                      min={0}
                      label="Coins"
                      autoComplete="totalCoins"
                      value={values.totalCoins}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      error={Boolean(touched.totalCoins && errors.totalCoins)}
                      helperText={touched.totalCoins && errors.totalCoins}
                    />
                    <Input
                      id="dailyFSGoal"
                      name="dailyFSGoal"
                      type="number"
                      min={0}
                      label="Daily Foot Steps Goal"
                      autoComplete="dailyFSGoal"
                      value={values.dailyFSGoal}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      error={Boolean(touched.dailyFSGoal && errors.dailyFSGoal)}
                      helperText={touched.dailyFSGoal && errors.dailyFSGoal}
                    />
                    <Select
                      id="gender"
                      name="gender"
                      className={
                        "text-left appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      }
                      value={values.gender}
                      label="Gender"
                      onChange={(e) => {
                        setFieldValue("gender", e);
                      }}
                      options={GENDER}
                      error={Boolean(touched.gender && errors.gender)}
                      helperText={touched.gender && errors.gender}
                    />
                    <Select
                      id="isTracking"
                      name="isTracking"
                      className={
                        "text-left appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      }
                      value={values.isTracking}
                      label="Tracking"
                      onChange={(e) => {
                        setFieldValue("isTracking", e);
                      }}
                      options={TRACKINGOPTIONS}
                      error={Boolean(touched.isTracking && errors.isTracking)}
                      helperText={touched.isTracking && errors.isTracking}
                    />
                    <Select
                      id="role"
                      name="role"
                      className={
                        "text-left appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      }
                      value={values.role}
                      label="Role"
                      onChange={(e) => {
                        setFieldValue("role", e);
                      }}
                      options={USERROLES}
                      error={Boolean(touched.role && errors.role)}
                      helperText={touched.role && errors.role}
                    />
                  </div>
                  {errors.submit && (
                    <p className="text-red-500 text-10 ml-2">{errors.submit}</p>
                  )}
                  <div className="text-right">
                    {isSubmitting ? (
                      <button
                        disabled={true}
                        className="inline-flex disabled:bg-gray-300 items-center justify-center rounded-md border border-transparent bg-purple px-4 py-2 my-3 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                      >
                        <Spinner size={4} /> Adding...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-purple px-4 py-2 my-3 text-sm font-medium text-white shadow-sm hover:bg-light-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                      >
                        Add User
                      </button>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(AddUser);
